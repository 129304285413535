import './Header.css'
import revolutionLogo from '../../images/revolutionLogo.PNG'
function Header(){

    return(
        <div className='Header'>
            <img src={revolutionLogo} alt="" className='logo'/>        
            <ul className='Header-Menu'>
                <li>Home</li>    
                <li>Programs</li>
                <li>Why Us</li>
                <li>Plans</li>
                <li>Testimonials</li>
            </ul>    
        </div>
    );
}

export default Header;